import { World } from './world.js';

function loadDoc(orderNo) {
	var doc;
	var xhttp = new XMLHttpRequest();
	xhttp.onreadystatechange = function() {
		if (this.readyState == 4 && this.status == 200) {
			doc=this.responseXML;
		}
	};
	xhttp.open("GET", "https://repl.flexlink.com/os/machining3d.htm?orderNo="+orderNo, false);
	xhttp.send();
	return doc;
}
function getUrlVars() {
	var vars = {};
	var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
		vars[key] = value;
	});
	return vars;
}
var world = new World(document, loadDoc(getUrlVars()["orderNo"]));