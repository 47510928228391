import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { DropdownModel } from './dropdownModel.js';


//const $ = require('jquery');

const cavityRenderOrder=100;
var cavityCount = 0;

export function World(document, xmlDoc) {

	//window.$ = window.jQuery = jQuery;
	const scene = new THREE.Scene();
	const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 15000);

	const renderer = new THREE.WebGLRenderer();
	renderer.localClippingEnabled = true;
	renderer.setSize(window.innerWidth, window.innerHeight);

	var controls = new OrbitControls(camera, renderer.domElement);

	controls.enableDamping = true;
	controls.dampingFactor = 0.05;
	controls.enablePan = true;
	controls.screenSpacePanning = false;

	controls.minDistance = 0;
	controls.maxDistance = 200;

	document.body.appendChild(renderer.domElement);
	camera.position.z = 200;

	const dropdownModel = new DropdownModel('dropdown-content');
	populateDropDown(dropdownModel, xmlDoc, scene, controls);
	//const fileSelector = document.getElementById("machining-def-file");
	//fileSelector.addEventListener('change', (event) => {
	//	const files = event.target.files;
	//	var reader = new FileReader();
	//	reader.onload = function (event) {
	//		var parser = new DOMParser();
	//		var xmlDoc = parser.parseFromString(event.target.result, "text/xml");
	//		populateDropDown(dropdownModel, xmlDoc, scene, controls);
	//	};
	//	reader.readAsText(files[0]);
	//});

	const animate = function () {
		requestAnimationFrame(animate);
		controls.update();
		renderer.render(scene, camera);
	};
	animate();
};
function populateDropDown(dropdownModel, xmlDoc, scene, controls) {
	var bounds = new THREE.Vector3();
	const material = new THREE.MeshNormalMaterial();
	const objLoader = new OBJLoader();
	const fontLoader = new THREE.FontLoader();
	var length = 0;

	var items = xmlDoc.getElementsByTagName("product");
	dropdownModel.clear();
	for (var i = 0; i < items.length; i++) {
		
		if (items[i].getElementsByTagName("machining").length == 0) {

			continue;
		}
		dropdownModel.createOption(items[i].getAttribute('ItemNumber')+ " "+items[i].getAttribute('Length'),
			items[i],
			function (bomItem) {
				while(scene.children.length > 0){ 
					scene.remove(scene.children[0]); 
				}
				var itemNumber = bomItem.getAttribute('ItemNumber');
				length = parseFloat(bomItem.getAttribute('Length'));
				controls.maxDistance = length*2;

				var startClipPlanes = new Array();
				var endClipPlanes = new Array();
				var startMaterial = new THREE.MeshNormalMaterial(
					{
						side: THREE.DoubleSide,
						clippingPlanes: startClipPlanes,
						clipIntersection:true
					});
				var fragShaderBeforeChange = `gl_FragColor = vec4( packNormalToRGB( normal ), opacity );`;
				var fragShaderAfterChange = `gl_FragColor = ( gl_FrontFacing ) ? vec4( packNormalToRGB( normal ), opacity ) : vec4( 0.5f,0.5f,0.5f, opacity );`;
				startMaterial.onBeforeCompile = function( shader ) {
					shader.fragmentShader = shader.fragmentShader.replace(
						fragShaderBeforeChange,
						fragShaderAfterChange
					);
				};
				var endMaterial = startMaterial.clone();
				endMaterial.clippingPlanes = endClipPlanes;
				endMaterial.clipIntersection = false;
				endMaterial.onBeforeCompile = function( shader ) {
					shader.fragmentShader = shader.fragmentShader.replace(
						fragShaderBeforeChange,
						fragShaderAfterChange
					);
				};
				objLoader.load(
					'geometry/'+itemNumber+'.obj',
					function (object) {
						var beamGroup = new THREE.Group();

						object.traverse(function (child) {
							if (child instanceof THREE.Mesh) {

								child.scale.x = length/2;
								child.material = startMaterial;
								child.geometry.computeBoundingBox();
								var box = new THREE.Box3();
								box.copy( child.geometry.boundingBox ).applyMatrix4( child.matrixWorld );
								bounds = box.getSize();
							}
						});
						var startProfile = object;
						var endProfile = object.clone();
						endProfile.position.x = length/2+0.01;
						endProfile.traverse(function (child) {
							if (child instanceof THREE.Mesh) {
								child.material = endMaterial;
							}
						});

						beamGroup.add(startProfile);
						beamGroup.add(endProfile);
						beamGroup.rotation.x = -1 * Math.PI / 2;

						
						scene.add(beamGroup);
						
						
						
						controls.target = new THREE.Vector3(length/2,0,0);
					}
				);

				//Side markings
				var sideLabelsAtStart = new THREE.Group();
				createTextMesh(sideLabelsAtStart, 'A', fontLoader, bounds, material);
				createTextMesh(sideLabelsAtStart, 'B', fontLoader, bounds, material);
				createTextMesh(sideLabelsAtStart, 'C', fontLoader, bounds, material);
				createTextMesh(sideLabelsAtStart, 'D', fontLoader, bounds, material);
				scene.add(sideLabelsAtStart);
				var sideLabelsAtEnd = new THREE.Group();
				sideLabelsAtEnd.position.x = length;
				createTextMesh(sideLabelsAtEnd, 'A', fontLoader, bounds, material, false);
				createTextMesh(sideLabelsAtEnd, 'B', fontLoader, bounds, material, false);
				createTextMesh(sideLabelsAtEnd, 'C', fontLoader, bounds, material, false);
				createTextMesh(sideLabelsAtEnd, 'D', fontLoader, bounds, material, false);
				scene.add(sideLabelsAtEnd);

				//Start/End markings
				var arrowLength = bounds.y/2;
				var arrowGeo = new THREE.ConeGeometry(arrowLength / 4, arrowLength / 2, 32);
				var startArrow = new THREE.Mesh(arrowGeo, material);
				startArrow.rotation.z = -Math.PI/2;
				startArrow.position.x = -arrowLength;
				scene.add(startArrow);
				var endArrow = new THREE.Mesh(arrowGeo, material);
				endArrow.rotation.z = startArrow.rotation.z;
				endArrow.position.x = length+arrowLength;
				scene.add(endArrow);

				//Machinings
				var holes = new THREE.Group();
				scene.add(holes);
				
				var machinings = bomItem.getElementsByTagName('machining');
				for (var j = 0; j < machinings.length; j++) {
					var operation =
						machinings[j].getElementsByTagName('operations')[0]
							.getElementsByTagName('operation')[0];
					var type = operation.getElementsByTagName('type')[0].textContent;
					var side = operation.getElementsByTagName('side')[0].textContent;
					
					if (type == 'Drilling') {
						var depth = parseFloat(operation.getElementsByTagName('depth')[0].textContent);
						var diameter = parseFloat(operation.getElementsByTagName('diameter')[0].textContent);
						var posX = parseFloat(
							operation.getElementsByTagName('position')[0].getElementsByTagName('x')[0]
							.textContent);
						var posY = parseFloat(
							operation.getElementsByTagName('position')[0].getElementsByTagName('y')[0]
							.textContent);
						var drilling = new Drilling(depth+1, diameter/2, new THREE.Vector3(posX, bounds.y/2 + 1-depth/2, posY), side);
						holes.add(drilling.cavity);
					}
					if (type == 'Milling') {
						var depth = parseFloat(operation.getElementsByTagName('depth')[0].textContent);
						var diameter = parseFloat(operation.getElementsByTagName('diameter')[0].textContent);
						var startX = parseFloat(
							operation.getElementsByTagName('start')[0].getElementsByTagName('x')[0]
							.textContent);
						var startY = parseFloat(
							operation.getElementsByTagName('start')[0].getElementsByTagName('y')[0]
							.textContent);
						var start = new THREE.Vector3(startX, bounds.y/2+1-depth/2, startY);
						var stopX = parseFloat(
							operation.getElementsByTagName('stop')[0].getElementsByTagName('x')[0]
							.textContent);
						var milling = new Milling(depth+1, diameter/2, start, side, Math.abs(startX-stopX));
						holes.add(milling.cavity);
					}
					if (type == 'AngleCutting') {
						var pos = parseFloat(
							operation.getElementsByTagName('position')[0].getElementsByTagName('x')[0]
							.textContent);
						var angle = parseFloat(
							operation.getElementsByTagName('angle')[0].textContent);
						var radianAngle = angle / 180 * Math.PI;
						var angleCut = new AngleCutting(new THREE.Vector3(pos, bounds.y/2, 0), side, radianAngle, bounds);
						if (pos >= 1) {
							endClipPlanes.push(angleCut.clipPlane);
						} else {
							startClipPlanes.push(angleCut.clipPlane);
						}

						//holes.add(new THREE.PlaneHelper(angleCut.clipPlane, 8000));

					}
				}
				
				
			});
	}
}
function createTextMesh(group, text, fontLoader, bounds, material, atStart = true) {
	var textMesh;
	fontLoader.load( 'fonts/helvetiker_regular.typeface.json', function ( font ) {

		const geometry = new THREE.TextGeometry(text, {
			font: font,
			size: 5,
			height: 1,
			curveSegments: 1,
			bevelEnabled: false
		} );
		textMesh = new THREE.Mesh(geometry, material);
		textMesh.rotation.y = Math.PI / 2 * (atStart ? -1 : 1);
		textMesh.geometry.computeBoundingBox();
		var box = new THREE.Box3();
		box.copy( textMesh.geometry.boundingBox ).applyMatrix4( textMesh.matrixWorld );
		var textBounds = box.getSize();
		textMesh.position.z += textBounds.x / 2 * (atStart ? -1 : 1);
		textMesh.position.y -= textBounds.y / 2;
		var offset = new THREE.Vector2(0,bounds.z/2+textBounds.y);
		offset.rotateAround(new THREE.Vector2(), getRotation(text));
		var centeringGroup = new THREE.Group();
		
		centeringGroup.position.y += offset.y;
		centeringGroup.position.z += offset.x;
		centeringGroup.add(textMesh);
		group.add(centeringGroup);
	} );
}
function getShearMatrix(side, radianAngle, scale) {
	var shearX = Math.tan(radianAngle)/scale;
	var shear = new THREE.Matrix4();
	if (side == 'A') {
		shear.set(
			1, 0, shearX, 0, 
			0, 1, 0, 0,
			0, 0, 1, 0,
			0, 0, 0, 1);
	}
	if (side == 'B') {
		shear.set(
			1, -shearX, 0, 0, 
			0, 1, 0, 0,
			0, 0, 1, 0,
			0, 0, 0, 1);
	}
	if (side == 'C') {
		shear.set(
			1, 0, -shearX, 0, 
			0, 1, 0, 0,
			0, 0, 1, 0,
			0, 0, 0, 1);
	}
	if (side == 'D') {
		shear.set(
			1, shearX, 0, 0, 
			0, 1, 0, 0,
			0, 0, 1, 0,
			0, 0, 0, 1);
	}
	return shear;

}
function getRotation(side)
{
	if (side == 'B')
		return -1*Math.PI / 2;
	else if (side == 'C')
		return Math.PI;
	else if (side == 'D')
		return Math.PI / 2;
	return 0;
}
function getFlippedRotation(side)
{
	if (side == 'B')
		return Math.PI / 2;
	else if (side == 'D')
		return -1*Math.PI / 2;
	return getRotation(side);
}
function createCavity(geometry, stencil = 16) {

	const group = new THREE.Group();

	var writeStencilColor = false;

	const frontStencil = new THREE.MeshBasicMaterial({ color: 0xffff00 });
	frontStencil.depthWrite = false;
	frontStencil.depthTest = true;
	frontStencil.colorWrite = writeStencilColor;
	frontStencil.stencilWrite = true;
	frontStencil.depthFunc = THREE.LessDepth;
	frontStencil.stencilRef = stencil;
	frontStencil.stencilFunc = THREE.NotEqualStencilFunc;
	frontStencil.stencilZFail = THREE.KeepStencilOp;
	frontStencil.stencilZPass = THREE.ReplaceStencilOp;
	frontStencil.side = THREE.BackSide;

	const backStencil = new THREE.MeshBasicMaterial({ color: 0xff00ff });
	backStencil.depthWrite = false;
	backStencil.depthTest = true;
	backStencil.colorWrite = writeStencilColor;
	backStencil.stencilWrite = true;
	backStencil.depthFunc = THREE.GreaterDepth;
	backStencil.stencilRef = stencil;
	backStencil.stencilFunc = THREE.NotEqualStencilFunc;
	backStencil.stencilZFail = THREE.KeepStencilOp;
	backStencil.stencilZPass = THREE.ReplaceStencilOp;
	backStencil.side = THREE.FrontSide;

	const cavityMat = new THREE.MeshNormalMaterial({ color: 0x000000 });
	cavityMat.depthTest = false;
	cavityMat.depthFunc = THREE.GreaterDepth;
	cavityMat.stencilWrite = true;
	cavityMat.stencilRef = stencil;
	cavityMat.stencilFunc = THREE.NotEqualStencilFunc;
	cavityMat.stencilZFail = THREE.ReplaceStencilOp;
	cavityMat.stencilZPass = THREE.ReplaceStencilOp;
	cavityMat.side = THREE.BackSide;

	const clearStencilMat = new THREE.MeshBasicMaterial({ color: 0x000000 });
	clearStencilMat.depthTest = false;
	clearStencilMat.colorWrite = false;
	clearStencilMat.stencilWrite = true;
	clearStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
	clearStencilMat.stencilZFail = THREE.ZeroStencilOp;
	clearStencilMat.stencilZPass = THREE.ZeroStencilOp;
	clearStencilMat.side = THREE.DoubleSide;

	const frontStencilMesh = new THREE.Mesh(geometry, frontStencil);
	//frontStencilMesh.renderOrder = renderOrder;
	group.add(frontStencilMesh);

	const backStencilMesh = new THREE.Mesh(geometry, backStencil);
	//backStencilMesh.renderOrder = renderOrder;
	group.add(backStencilMesh);

	const cavityMesh = new THREE.Mesh(geometry, cavityMat);
	//cavityMesh.renderOrder = renderOrder;
	group.add(cavityMesh);

	const clearMesh = new THREE.Mesh(geometry, clearStencilMat);
	//clearMesh.renderOrder = renderOrder;
	group.add(clearMesh);
	group.renderOrder = cavityRenderOrder + cavityCount;
	cavityCount++;
	return group;

}

function getMilling(length, radius, depth) {
	const group = new THREE.Group();
	var cylynderGeo = new THREE.CylinderBufferGeometry(radius, radius, depth, 32);
	var boxGeo = new THREE.BoxBufferGeometry(length, depth, radius * 2);
	var startCavity = createCavity(cylynderGeo);
	var endCavity = createCavity(cylynderGeo);
	endCavity.position.x = length;
	var middleCavity = createCavity(boxGeo);
	middleCavity.position.x = length / 2;
	group.add(startCavity);
	group.add(endCavity);
	group.add(middleCavity);
	return group;
}
class Drilling {
	constructor(depth, radius, position, side) {
		this.depth = depth;
		this.radius = radius;
		this.position = position;
		this.side = side;
		this.geometry = new THREE.CylinderBufferGeometry(radius, radius, depth, 32);
	}
	get cavity()
	{
		var cav = createCavity(this.geometry);
		cav.position.x = this.position.x;
		cav.position.y = Math.sin(this.rotation)*this.position.z+Math.cos(this.rotation)*this.position.y;
		cav.position.z = Math.cos(this.rotation)*this.position.z-Math.sin(this.rotation)*this.position.y;

		cav.rotation.x = this.rotation;
		return cav;
	}
	get rotation() {
		return getRotation(this.side);
	}
	get pos() {
		return new THREE.Vector3;
	}
}

class Milling {
	constructor(depth, radius, position, side, length) {
		this.depth = depth;
		this.radius = radius;
		this.position = position;
		this.side = side;
		this.length = length;
		this.geometry = new THREE.CylinderBufferGeometry(radius, radius, depth, 32);
	}
	get cavity() {
		var cav = getMilling(this.length, this.radius, this.depth);//createCavity(this.geometry);
		cav.position.x = this.position.x;
		cav.position.y = Math.sin(this.rotation)*this.position.z+Math.cos(this.rotation)*this.position.y;
		cav.position.z = Math.cos(this.rotation)*this.position.z-Math.sin(this.rotation)*this.position.y;
		cav.rotation.x = this.rotation;
		return cav;
	}
	get rotation()
	{
		return getRotation(this.side);
	}
}

class AngleCutting {
	constructor(position, side, radianAngle, beamBounds) {

		this.position = position;
		this.side = side;
		this.radianAngle = radianAngle;
		this.beamBounds = beamBounds;
	}
	get clipPlane() {
		var isAtEnd = this.position.x >= 1;
		var dir = new THREE.Vector3(isAtEnd?-1:1, 0, 0 );

		var axis = new THREE.Vector3( 0, 0, 1 );
		var angle = this.radianAngle * (isAtEnd ? 1 : -1);
		dir.applyAxisAngle( axis, angle );
		axis = new THREE.Vector3( 1, 0, 0 );
		dir.applyAxisAngle( axis, getFlippedRotation(this.side) );
		var dist = 0;
		var plane = new THREE.Plane(dir, dist);
		var target = new THREE.Vector3(0, -this.beamBounds.y / 2, 0);
		target.applyAxisAngle( axis, getFlippedRotation(this.side) );
		if (isAtEnd) {
			target.x = this.position.x;
		} 
		plane.translate(target);
		return plane;
	}
}