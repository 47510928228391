
export class DropdownModel {
    constructor(elementId) {
        this.elementId = elementId;
    }

    clear() {
        var select = document.getElementById(this.elementId);
        var length = select.children.length;
        for (var i = length - 1; i >= 0; i--) {
            select.removeChild(select.children[i]);
        }
    }
    createOption(value, bomItem, onclick) {
        console.log(value);
        var el = document.createElement('button');
        el.value = value;
        el.innerHTML = value;
        el.id = value;
        el.addEventListener("click",
            function() {
                onclick(bomItem);
            });
        document.getElementById(this.elementId).appendChild(el);
    }
}